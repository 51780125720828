module.exports = {
  pathPrefix: "/", // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
  title: "Oria 2 Brochure", // Navigation and Site Title
  titleAlt: "Oria 2", // Title for JSONLD
  description: "Brochure site for Oria 2 condominiums",
  headline: "Brochure site for Oria 2 condominiums", // Headline for schema.org JSONLD
  url: "https://oria2brochure.com", // Domain of your site. No trailing slash!
  logo: "/src/logo-or-oria-condominiums-1", // Used for SEO
  ogLanguage: "en_CA", // Facebook Language

  // JSONLD / Manifest
  favicon: "src/favicon.png", // Used for manifest favicon generation
  shortName: "Oria2", // shortname for manifest. MUST be shorter than 12 characters
  author: "Bivouac Studio | https://www.bivouacstudio.com", // Author for schemaORGJSONLD
  themeColor: "#C78866",
  backgroundColor: "rgb(243, 229, 219)",

  twitter: "Oria 2 Brochure", // Twitter Username
  facebook: "Oria 2 Brochure", // Facebook Site Name
  googleAnalyticsID: "UA-XXXXXX-X",

  skipNavId: "reach-skip-nav", // ID for the "Skip to content" a11y feature
};
