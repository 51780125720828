import styled from "@emotion/styled";
import { Link, navigate } from "gatsby";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useRef, useState } from "react";
import logo from "../assets/images/logo-or-oria-condominiums-1-new.png";
import Burger from "../components/Burger";
import MobileMenu from "../components/MobileMenu";
import { useOnClickOutside } from "../hooks";
import { theme } from "../styles";
import { LocaleContext } from "./Layout";

const HeaderContainer = styled.header`
  @media (max-width: ${theme.breakpoints.m}) {
   position: sticky;
    top:0px;
    background-color: ${theme.colors.bg};
    z-index:9;
  }  
 
}
`;

const StyledHeader = styled.nav`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 0px 36px 0  36px;
  border-bottom: 1px solid ${theme.colors.elegance};
  min-height: 6vh;
  max-width: 100%;

  @media (max-width: ${theme.breakpoints.m}) {
    padding: 0 2rem 0 0;
    justify-content: space-between;
  }  

  @media (max-width: ${theme.breakpoints.s}) {
    padding: .4rem 1rem;
    flex-wrap: nowrap;
    width: 100%;
  }  
}
`;

const LogoWrapper = styled.div`
  max-width: 360px;
  margin: 17px;
`;

const Logo = styled.img`
  max-height: 100%;
  max-width: 100%;

  @media (max-width: ${theme.breakpoints.s}) {
    margin-left: -1rem;
  }
`;

const Inscription = styled.button`
  border-spacing: 0;
  font-weight: normal;
  font-size: 1rem;
  line-height: 0;
  background: none;
  list-style: none outside none;
  background-color: ${theme.colors.elegance};
  padding: 0.9rem 0.8rem 1rem 0.8rem;
  letter-spacing: 0.8px;
  margin: 0;
  border: 0;
  margin-left: -1rem;
  color: ${theme.colors.bg};
  text-transform: uppercase;
  cursor: pointer;
  @media (max-width: ${theme.breakpoints.s}) {
    font-size: 0.8rem;
    padding: 0.6rem 0.5rem 0.8rem 0.5rem;
  }
`;

const Language = styled.div`
  flex: 1;
`;

const LocaleSwitcher = styled.div`
  text-align: right;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  margin-left: auto;
  width: 250px;
  @media (max-width: ${theme.breakpoints.s}) {
    width: 100%;
    max-width: 100%;
    padding-bottom: 0.6rem;
    margin-left: -5px;
  }
`;

const Header = () => {
  const [width, setWidth] = useState("");
  const [, setHeight] = useState("");
  const [open, setOpen] = useState(false);

  const lang = useContext(LocaleContext);
  const i18n = lang.i18n[lang.locale];

  const node = useRef();
  useOnClickOutside(node, () => setOpen(false));

  useEffect(() => {
    if (typeof window !== "undefined") {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    }
  });

  const handleLogo = () => {
    if (typeof window !== "undefined") {
      if (width < 899 && i18n && i18n.path === "en") {
        navigate("/en/phase-two");
      }

      if (width < 899 && i18n && i18n.path === "fr") {
        navigate("/phase-deux");
      }

      if (width > 899) {
        window.location.reload();
      }
    }
  };

  return (
    <HeaderContainer>
      <StyledHeader>
        {i18n && i18n.path === "en" ? (
          <LogoWrapper>
            <Logo src={logo} alt="Oria 2 logo" onClick={handleLogo} />
          </LogoWrapper>
        ) : (
          <LogoWrapper>
            <Logo src={logo} alt="Oria 2 logo" onClick={handleLogo} />
          </LogoWrapper>
        )}

        <div ref={node} style={{ display: "block", zIndex: 999 }}>
          {width && width < 899 && <Burger open={open} setOpen={setOpen} />}
          {width && width < 899 && <MobileMenu open={open} setOpen={setOpen} />}
        </div>

        {/*        {width && width < 899 && <MenuOverlay open={open} setOpen={setOpen} />} */}

        {width && width > 899 && (
          <LocaleSwitcher data-name="locale-switcher">
            {i18n && i18n.path === "en" ? (
              <a
                href="https://oriacondominiums.com/en/contact/"
                rel="noreferrer"
              >
                <Inscription>Register Now!</Inscription>{" "}
              </a>
            ) : (
              <a href="https://oriacondominiums.com/contact/" rel="noreferrer">
                <Inscription>Inscrivez-vous!</Inscription>{" "}
              </a>
            )}

            <Language>
              {i18n && i18n.path === "en" ? (
                <Link hrefLang="fr-ca" to="/">
                  FR
                </Link>
              ) : (
                <Link hrefLang="en-ca" to="/en">
                  EN
                </Link>
              )}
            </Language>
          </LocaleSwitcher>
        )}
      </StyledHeader>
    </HeaderContainer>
  );
};

export default Header;

Header.propTypes = {
  invert: PropTypes.bool,
};

Header.defaultProps = {
  invert: false,
};
