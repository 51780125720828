const theme = {
  colors: {
    primary: "#3D63AE",
    bg: "rgb(243, 229, 219)",
    flore: "#18361E",
    rose: "#DFB8AF",
    quietude: "#949C89",
    elegance: "#C78866",
    elegance_dark: "#944E34",
  },
  fonts: {
    main: "Agatho light",
  },
  accordion: {
    slideTitleWidthDesktop: "55px",
    slideTitleWidthTablet: "35px",
    desktopM: "385px" /* number of slides multiply by slideTitleWidth*/,
    tablet: "245px" /* number of slides multiply by slideTitleWidth*/,
  },
  maxWidth: "1000px",
  maxWidthText: "720px",
  breakpoints: {
    xs: "400px",
    s: "600px",
    m: "900px",
    l: "1200px",
  },
};

export default theme;
