/* eslint no-unused-expressions: 0 */
/* eslint react/destructuring-assignment: 0 */

import { css, Global } from "@emotion/core";
import "@reach/skip-nav/styles.css";
import { ThemeProvider } from "emotion-theming";
import PropTypes from "prop-types";
import React from "react";
import "typeface-lora";
import "typeface-source-sans-pro";
import i18n from "../../config/i18n";
import { reset, theme } from "../styles";
import Header from "./Header";
import "./Layout.css";
import SkipNavLink from "./SkipNavLink";
import flecheL from "../assets/images/fleche_L.png";
import flecheR from "../assets/images/fleche_R.png";

const globalStyle = css`
  ${reset}
  h1, h2, h3, h4, h5, h6 {
    color: ${theme.colors.black};
    font-family: ${theme.fonts.main};
    font-weight: 400;
  }
  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    @media (min-width: ${theme.breakpoints.m}) {
      overflow-y: hidden;
    }
  }
  body {
    color: ${theme.colors.greyDarker};
    background-color: ${theme.colors.bg};
    font-family: ${theme.fonts.main};
    font-weight: 400;
  }
  ::selection {
    color: ${theme.colors.bg};
    background-color: ${theme.colors.primary};
  }
  a {
    color: ${theme.colors.elegance};
    transition: all 0.4s ease-in-out;
    text-decoration: none;
    font-weight: 400;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  @media (max-width: ${theme.breakpoints.l}) {
    h4 {
      font-size: 2.2rem;
    }
  }

  @media (max-width: ${theme.breakpoints.m}) {
    html {
      font-size: 16px !important;
    }

    h2 {
      font-size: 5.4rem !important;
      margin-bottom: 0;
    }
  }
  @media (max-width: ${theme.breakpoints.s}) {
    h1 {
      font-size: 4rem !important;
    }
    h2 {
      font-size: 3.4rem !important;
      margin-bottom: 0;
    }
    h3 {
      font-size: 3rem !important;
    }
    h4 {
      font-size: 1.7rem !important;
    }
    h5 {
      font-size: 1.2rem !important;
    }
    h6 {
      font-size: 0.8rem !important;
    }
  }

  @media (max-width: ${theme.breakpoints.xs}) {
    h1 {
      font-size: 4rem !important;
    }
    h2 {
      font-size: 2.4rem !important;
      margin-bottom: 0;
    }
    h3 {
      font-size: 3rem !important;
    }
    h4 {
      font-size: 1.7rem !important;
    }
    h5 {
      font-size: 1.2rem !important;
    }
    h6 {
      font-size: 0.8rem !important;
    }
  }

  ::-moz-selection {
    /* Code for Firefox */
    color: ${theme.colors.bg};
    background: ${theme.colors.bg};
  }

  ::selection {
    color: ${theme.colors.bg};
    background: ${theme.colors.bg};
  }

  ::-webkit-scrollbar {
    width: 10px;
  }
  body {
    scrollbar-width: thin;
    scrollbar-color: ${theme.colors.bg};
  }
  ::-webkit-scrollbar-track {
    background: ${theme.colors.bg};
  }
  ::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG);
    border-radius: 6px;
    border: 3px solid ${theme.colors.bg};
  }

  /*  -webkit-transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;
  -webkit-transform-style: flat; */

  .accordion-select {
    cursor: pointer;
    margin: 0;
    opacity: 0;
    z-index: 1;
  }

  .accordion-title {
    position: relative;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    text-transform: uppercase;
    font-family: ${theme.fonts.main};
    @media (min-width: 3400px) {
      flex-direction: column;
      justify-content: center;
      height: calc(100vh - 6vh) !important;
    }
  }

  .accordion-title:not(:nth-last-of-type(2))::after {
    border: 1px solid transparent;
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: -1px;
  }

  .accordion-title span {
    bottom: 0px;
    box-sizing: border-box;
    display: block;
    white-space: nowrap;
    width: 100%;
    font-size: 1rem;
    letter-spacing: 0.8px;

    @media (max-width: ${theme.breakpoints.l}) {
      font-size: 0.8rem;
    }
  }

  .accordion-content {
    box-sizing: border-box;
    overflow: auto;
    position: relative;
    transition: margin 1200ms cubic-bezier(0.22, 1, 0.36, 1);
    border-right: 1px solid ${theme.colors.elegance};
    margin-right: calc(-1 * calc(100% - ${theme.accordion.desktopM}));
    width: calc(100% - ${theme.accordion.desktopM});
    z-index: 0;
    overflow: hidden;
  }

  .accordion-select:checked + .accordion-title + .accordion-content {
    margin-bottom: 0;
    margin-right: 0 !important;
  }

  /* Generated styles starts here */

  .accordion-title,
  .accordion-select {
    background-color: ${theme.colors.bg};
    color: ${theme.colors.elegance};
    width: ${theme.accordion.slideTitleWidthDesktop};
    height: 100%;
    font-size: 16px;
  }

  .accordion-select {
    margin-bottom: -${theme.accordion.slideTitleWidthDesktop};
    margin-right: -${theme.accordion.slideTitleWidthDesktop};
  }

  .accordion-title:not(:nth-last-of-type(2))::after {
    border-bottom-color: transparent;
    border-right-color: ${theme.colors.elegance};
  }

  .accordion-select:checked:last-of-type + .accordion-title {
    border-right: 1px solid ${theme.colors.elegance};
  }

  .accordion-select:hover + .accordion-title,
  .accordion-select:checked + .accordion-title {
    background-color: ${theme.colors.bg};
  }

  .accordion-title span {
    transform: rotate(-90deg);
    -ms-writing-mode: lr-bt;
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
    padding-bottom: 28px;
    padding-top: 28px;
    line-height: ${theme.accordion.slideTitleWidthDesktop};
  }

  @media (max-width: 1400px) {
    .accordion-title,
    .accordion-select {
      width: ${theme.accordion.slideTitleWidthTablet};
      font-size: 14px;
    }
    .accordion-select {
      margin-bottom: -${theme.accordion.slideTitleWidthTablet};
      margin-right: -${theme.accordion.slideTitleWidthTablet};
    }

    .accordion-title span {
      line-height: ${theme.accordion.slideTitleWidthTablet};
    }

    .accordion-content {
      margin-right: calc(-1 * calc(100% - ${theme.accordion.tablet}));
      width: calc(100% - ${theme.accordion.tablet});
    }
  }

  /* .placeholders {
  transition: all 0.4s;
}

.placeholders:hover {
  transform: scale(1.1);
}
 */

  .page-number {
    position: absolute;
    bottom: 24px;
    font-size: 1rem;

    @media (max-width: 1100px) {
      bottom: 24px;
      font-size: 0.8rem;
    }

    @media (min-width: 2560px) {
      bottom: 38px;
    }
  }

  @media (max-width: ${theme.breakpoints.s}) {
    font-size: 1rem;
  }

  .page-container__homepage {
    display: flex;
    height: 100vh;
    align-items: start;
    justify-content: center;
    background-color: #d4a387;
  }

  .image-test {
    width: 100%;
    margin: 0 auto;
  }

  @media only screen and (min-width: 899px) {
    .gatsby-image-wrapper {
      margin: 0 auto;
      height: calc(100vh - 73px);
    }
  }

  @media only screen and (min-width: 2200px) {
    .gatsby-image-wrapper {
      margin: 0 auto;
      height: calc(100vh - 6vh);
    }
  }

  @media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
    .image-test.image2 img,
    .image-text__right img {
      object-fit: contain !important;
    }

    .gatsby-image-wrapper {
      margin: 0 auto;
      height: calc(100vh - 73px);
    }
  }

  @media only screen and (min-width: 1300px) {
    img.image-test.home,
    img.image-test.home__ligne {
      position: absolute;
      padding: 10rem;
      box-sizing: border-box;
      margin-top: -201px;
    }
  }

  .page-container {
    display: flex;
    height: 100%;

    align-items: center;
    justify-content: center;
    box-shadow: 0 0 0 #000;
    -webkit-transform: translate3d(0, 0, 0);
    @media screen and (min-width: 2200px) {
      height: 100%;
    }
  }

  img.image-test.home,
  img.image-test.home__ligne {
    position: absolute;
    top: 0;
    left: 0;
  }

  .img-container__center,
  .img-container__left,
  .img-container__right {
    position: relative;
    /*     padding-bottom: 4rem; */
    height: 100%;
    display: flex;
    align-items: center;
    flex: 1;
    -webkit-backface-visibility: hidden;
    -webkit-transform: scale(1);
    -webkit-transform-style: preserve-3d;
    box-shadow: 0 0 0 #000;
    -webkit-transform: translate3d(0, 0, 0);
  }

  .img-container__center img,
  .img-container__left img,
  .img-container__right img {
    -webkit-backface-visibility: hidden;
    -webkit-transform: scale(1);
    -webkit-transform-style: preserve-3d;
    box-shadow: 0 0 0 #000;
    -webkit-transform: translate3d(0, 0, 0);
  }

  @media only screen and (max-width: 1300px) {
    .img-container__center,
    .img-container__left,
    .img-container__right {
      padding: 0px;
    }
  }

  .img-first {
    -webkit-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  .image-text__left,
  .image-text__right {
    position: absolute;
    width: 100%;
    -webkit-backface-visibility: hidden;
    -webkit-transform: scale(1);
    -webkit-transform-style: preserve-3d;
    transform: translate3d(0, 0, 0);
    box-shadow: 0 0 0 #000;
    -webkit-transform: translate3d(0, 0, 0);
    box-sizing: border-box;
    padding: 0;
  }

  .mobile-block-container {
    overflow: hidden;
  }

  .image-stack-container-left,
  .image-stack-container-right {
    position: relative;
  }

  .image-mobile {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    transform: translate(-50%, -50%) !important;
  }

  .text__mobile {
    position: relative;
    font-family: ${theme.fonts.main};
    font-size: 3rem;
    line-height: 1;
    padding: 2rem 4rem !important;
    @media (max-width: ${theme.breakpoints.s}) {
      font-size: 1.5rem;
      line-height: 1;
      padding: 1rem 2rem !important;
    }
  }

  .page-les-realisateurs,
  .page-the-creators {
    .text__mobile {
      a {
        color: ${theme.colors.bg};
      }
    }
  }

  .wrapper-text__only {
    display: block;

    height: 100vh;
    padding: 60px 30px;
    width: 100%;
  }
  .text__only {
    font-family: ${theme.fonts.main};
    font-size: 22px;
    padding: 0px 30px;
    box-sizing: border-box;
    display: block;
    height: 100%;
    max-width: 1100px;
    margin: 100px auto 0 auto;
  }

  .center-align {
    display: block;
    text-align: center;
    font-family: ${theme.fonts.main};
  }

  .right-align {
    display: block;
    text-align: right;
    font-family: ${theme.fonts.main};
  }

  .swiper-slide {
    width: 100% !important;
  }

  .swiper-button-prev,
  .swiper-button-next {
    color: ${theme.colors.elegance} !important;
    stroke: transparent !important;
    stroke-width: 0%;
    outline: none !important;
    ackground-position: center;
    margin-top: -56px;

    @media (max-width: 1367px) {
      margin-top: -86px;
    }
  }

  .swiper-button-prev:after,
  .swiper-button-next:after {
    color: ${theme.colors.elegance} !important;
    font-weight: 300;
    stroke-width: 0%;
  }

  @media only screen and (max-width: 979px) {
    .swiper-button-prev:after,
    .swiper-button-next:after {
      font-size: 2rem !important;
    }
  }

  .swiper-pagination {
    bottom: calc(100% - 40px) !important;
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
    padding-right: 0.9rem;
    outline: none !important;
  }

  /* 
.swiper-button-prev {
  background-image: url("");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}

.swiper-button-prev::after {
  display: none;
}

.swiper-button-next {
  background-image: url("");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}

.swiper-button-next::after {
  display: none;
}
 */

  #accordion-0 .swiper-pagination {
    display: none;
  }

  @media only screen and (min-width: 2200px) {
    #accordion-0 .image-text__left img {
      object-fit: contain !important;
    }
  }

  .swiper-container-horizontal
    > .swiper-pagination-bullets
    .swiper-pagination-bullet {
    margin: 0 8px;
    font-size: 32px;
    top: 2px;
    position: relative;
  }
  .swiper-pagination-bullet {
    color: #cd977a;
    width: 0px;
    height: auto;
    border-radius: 0;
    white-space: nowrap;
    display: inline-block;
    background: none;
    outline: none !important;
  }

  .swiper-button-prev:after,
  .swiper-button-next:after {
    padding: 3rem 2rem;
  }

  .swiper-button-disabled {
    display: none;
  }

  .swiper-button-prev {
    background-image: url(${flecheL});
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    padding: 1.4rem;
  }

  .swiper-button-next {
    background-image: url(${flecheR});
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    padding: 1.4rem;
  }

  .swiper-button-prev::after {
    display: none;
  }

  .swiper-button-next::after {
    display: none;
  }
`;

const LocaleContext = React.createContext();

const Layout = ({ children, pageContext: { locale } }) => {
  return (
    <LocaleContext.Provider value={{ locale, i18n }}>
      <ThemeProvider theme={theme}>
        <>
          <Global styles={globalStyle} />

          <Header />

          {children}
        </>
      </ThemeProvider>
    </LocaleContext.Provider>
  );
};

export { LocaleContext, Layout };

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
};
