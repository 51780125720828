const website = require("./website");

module.exports = {
  "fr-ca": {
    default: true,
    path: "fr",
    locale: "fr-ca",
    siteLanguage: "fr",
    ogLang: "fr_FR",
    defaultTitle: website.title,
    defaultTitleAlt: website.titleAlt,
    defaultDescription: "Site brochure Oria 2",
    headline: "",
    category: "",
    categories: "",
    was: "",
    were: "",
    tagged: "",
    recent: "",
    projects: "",
    allCategories: "",
    entries: "",
  },
  "en-ca": {
    path: "en",
    locale: "en-ca",
    siteLanguage: "en",
    ogLang: "en_CA",
    defaultTitle: website.title,
    defaultTitleAlt: website.titleAlt,
    defaultDescription: website.description,
    headline: website.headline,
    category: "Category",
    categories: "Categories",
    was: "was",
    were: "were",
    tagged: "tagged with",
    recent: "Recent",
    projects: "projects",
    allCategories: "All categories",
    entries: "entries",
  },
};
