import { Link } from "gatsby";
import { bool } from "prop-types";
import React, { useContext } from "react";
import { LocaleContext } from "../../components/Layout";
import { StyledMenu } from "./MobileMenu.styled";

const Menu = ({ open, setOpen }) => {
  const lang = useContext(LocaleContext);
  const i18n = lang.i18n[lang.locale];

  return (
    <StyledMenu open={open}>
      {i18n && i18n.path === "en" ? (
        <>
          <Link
            onClick={() => setOpen(!open)}
            className="menu-item"
            to="/en/phase-two"
          >
            Home
          </Link>
          <span className="divider"></span>
          <Link
            onClick={() => setOpen(!open)}
            className="menu-item"
            to="/en/the-project"
          >
            The project
          </Link>
          <span className="divider"></span>
          <Link
            onClick={() => setOpen(!open)}
            className="menu-item"
            to="/en/architecture"
          >
            Architecture
          </Link>
          <span className="divider"></span>
          <Link
            onClick={() => setOpen(!open)}
            className="menu-item"
            to="/en/living-spaces"
          >
            Living spaces
          </Link>
          <span className="divider"></span>
          <Link
            onClick={() => setOpen(!open)}
            className="menu-item"
            to="/en/the-units"
          >
            The Units
          </Link>
          <span className="divider"></span>

          <Link
            onClick={() => setOpen(!open)}
            className="menu-item"
            to="/en/the-neighbourhood"
          >
            The Neighbourhood
          </Link>
          <span className="divider"></span>
          <Link
            onClick={() => setOpen(!open)}
            className="menu-item"
            to="/en/the-creators"
          >
            The creators
          </Link>
          <span className="divider"></span>
          <Link onClick={() => setOpen(!open)} className="menu-item" to="/">
            Fr
            <span className="divider"></span>{" "}
          </Link>
        </>
      ) : (
        <>
          <Link
            onClick={() => setOpen(!open)}
            className="menu-item"
            to="/phase-deux"
          >
            Accueil
            <span className="divider"></span>{" "}
          </Link>
          <span className="divider"></span>
          <Link
            onClick={() => setOpen(!open)}
            className="menu-item"
            to="/le-projet"
          >
            Le projet
          </Link>
          <span className="divider"></span>
          <Link
            onClick={() => setOpen(!open)}
            className="menu-item"
            to="/larchitecture"
          >
            L'architecture
          </Link>
          <span className="divider"></span>
          <Link
            onClick={() => setOpen(!open)}
            className="menu-item"
            to="/aires-de-vie"
          >
            Aires de vie
          </Link>

          <span className="divider"></span>

          <Link
            onClick={() => setOpen(!open)}
            className="menu-item"
            to="/les-unites"
          >
            Les Unites
          </Link>
          <span className="divider"></span>

          <Link
            onClick={() => setOpen(!open)}
            className="menu-item"
            to="/le-quartier"
          >
            Le Quartier
          </Link>
          <span className="divider"></span>
          <Link
            onClick={() => setOpen(!open)}
            className="menu-item"
            to="/les-realisateurs"
          >
            Les réalisateurs
          </Link>
          <span className="divider"></span>
          <Link
            onClick={() => setOpen(!open)}
            className="menu-item"
            to="/en/phase-two"
          >
            En
            <span className="divider"></span>{" "}
          </Link>
          {/*           <a href="https://oriacondominiums.com/contact/" target="_blank">
            Inscrivez-vous!
          </a> */}
        </>
      )}
    </StyledMenu>
  );
};
Menu.propTypes = {
  open: bool.isRequired,
};
export default Menu;
