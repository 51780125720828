import styled from "@emotion/styled";
import { reset, theme } from "../../styles/index";

export const StyledMenu = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: ${({ theme }) => theme.colors.bg};
  height: 100vh;
  text-align: center;
  padding-top: 2rem;
  position: absolute;
  top: 0px;
  left: 0;
  width: 100%;
  transition: transform 0.3s ease-in-out;
  z-index: -1;

  @media (max-width: ${({ theme }) => theme.colors.elegance}) {
    width: 100%;
  }

  a {
    font-size: 1rem;
    text-transform: uppercase;

    @media (max-width: ${theme.breakpoints.m}) {
      font-size: 1.2rem;
      padding: 1rem 0;
    }
    @media (max-width: ${theme.breakpoints.s}) {
      font-size: 1rem;
      padding: 0.6rem 0;
    }
    color: ${({ theme }) => theme.colors.elegance};
    text-decoration: none;
    transition: color 0.3s linear;

    &:hover {
      color: ${({ theme }) => theme.colors.elegance};
    }
  }
  transform: ${({ open }) => (open ? "translateX(0)" : "translateX(-100%)")};

  .divider {
    border-bottom: 1px solid ${({ theme }) => theme.colors.elegance};
  }
`;
