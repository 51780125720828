import { bool, func } from "prop-types";
import React from "react";
import HamburgerMenu from "react-hamburger-menu";
import { theme } from "../../styles";

const Burger = ({ open, setOpen }) => {
  return (
    <HamburgerMenu
      isOpen={open}
      menuClicked={() => setOpen(!open)}
      width={34}
      height={22}
      strokeWidth={1}
      rotate={0}
      color={theme.colors.elegance}
      borderRadius={0}
      animationDuration={0.5}
    />
  );
};
Burger.propTypes = {
  open: bool.isRequired,
  setOpen: func.isRequired,
};
export default Burger;
